import React from 'react';

import './Success.css';

const AccessSuccess = () => {
  return (
    <aside className='success-container'>
      <h3 className='success-title'>Su entrada se ha reservado con exito</h3>
    </aside>
  );
};

export default AccessSuccess;
